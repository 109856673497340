export const ANCHOR_LINKS = [
  {
    label: 'Займ онлайн',
    link: '/zaimy/onlain/',
  },
  {
    label: 'Займ на карту',
    link: '/zaimy/na-kartu/',
  },
  {
    label: 'Займ без отказа',
    link: '/zaimy/bez-otkaza/',
  },
  {
    label: 'Займ с плохой кредитной историей',
    link: '/zaimy/s-plokhoj-kreditnoj-istoriej/',
  },
  { label: 'Займ по паспорту', link: '/zaimy/po-pasportu/' },
  { label: 'Займ по телефону', link: '/zaimy/po-telefonu/' },
  {
    label: 'Займ без процентов',
    link: '/zaimy/bez-protsentov/',
  },
  {
    label: 'Займ с просрочками',
    link: '/zaimy/s-prosrochkami/',
  },
  {
    label: 'Займ до зарплаты',
    link: '/zaimy/na-kartu-do-zarplaty/',
  },
  {
    label: 'Займы под залог ПТС',
    link: '/zaimy/pod-pts/',
  },
  {
    label: 'Займ через госуслуги',
    link: '/zaimy/cherez-gosuslugi/',
  },
] as const;
