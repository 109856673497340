import { useRouter } from 'next/router';

import { ANCHOR_LINKS } from './AnchorLinkList.config';
import styles from './AnchorLinkList.module.scss';

export const AnchorLinkList = () => {
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  return (
    <div className={styles.container}>
      {ANCHOR_LINKS.map(({ label, link }) => {
        const active = pathWithoutQuery === link;

        return (
          <div key={label} className={active ? styles.badgeActive : styles.badge}>
            {active ? label : <a href={link}>{label}</a>}
          </div>
        );
      })}
    </div>
  );
};
